<template>
  <div id="form_masuk">
    <b-row align-h="between" align-v="center">
      <b-col sm="4" class="d-flex align-items-center mb-2 mb-sm-0">
        <b-button variant="outline-secondary" @click="$router.go(-1)">
          <feather-icon icon="ArrowLeftIcon" :style="{ cursor: 'pointer' }" />
          <span>Kembali</span>
        </b-button>
      </b-col>
    </b-row>
    <overlay-loading :loading="fetchLoading">
      <form-wizard
        ref="wizard"
        color="#7367F0"
        title="Form Type 2"
        :subtitle="formTitle"
        layout="vertical"
        :finish-button-text="
          $router.currentRoute.params.id !== undefined
            ? 'Simpan Revisi'
            : 'Terbitkan Form'
        "
        back-button-text="Previous"
        :lazy="true"
        :before-change="() => true"
        class="vertical-steps steps-transparent mb-4"
        @on-complete="formSubmitted"
        @on-change="onTabChange"
      >
        <!-- Transportir details tab -->
        <tab-content
          v-if="!form.isAgenAndTrans"
          title="Transportir"
          icon="feather icon-package"
          :lazy="true"
          :before-change="validationFormTransportir"
        >
          <validation-observer ref="transportirRules" tag="form">
            <b-row v-if="transportirOverview">
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  Transportir
                  <b-badge
                    href="#"
                    pill
                    :variant="statusSuggestionColor[transportirState]"
                  >
                    <strong
                      ><span v-text="statusSuggestion[transportirState]"
                    /></strong>
                  </b-badge>
                </h5>

                <small class="text-muted">
                  {{ this.$t('Create Your ') }}Transportir{{
                    this.$t(' or Use data on already exist')
                  }}.
                  <strong>Mulai</strong> <code>09/03/2024</code> data Transportir/Tab menu ini bersifat Public ( Tidak terikat pada <code> Jenis/Group</code> Form)
                </small>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="No Dokumen"
                  label-for="vi-schedule-no_document"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="No Dokumen"
                    rules="required"
                  >
                    <b-form-input
                      id="vi-schedule-no_document"
                      v-model="form.schedule.no_document"
                      placeholder="No Document"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Name Transportir"
                  label-for="vi-transportir-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Name Transportir"
                    rules="required"
                  >
                    <vue-autosuggest
                      id="vi-transportir-name"
                      v-model="form.transportir.name_transportir"
                      v-uppercase
                      :suggestions="[{ data: form.transportir.transportirs }]"
                      :get-suggestion-value="getSuggestionValue"
                      :input-props="{
                        id: 'autosuggest__input',
                        class: 'form-control',
                        placeholder: 'Name transportir'
                      }"
                      @focus="
                        getSuggestionValueFocus(
                          $event,
                          'transportir',
                          'name_transportir'
                        )
                      "
                      @input="
                        searchForm(
                          $event,
                          'transportir/',
                          'transportir',
                          'name_transportir'
                        )
                      "
                    >
                      <template slot-scope="{ suggestion }">
                        <div class="d-flex align-items-center">
                          <div class="detail ml-50">
                            <b-card-text class="mb-0">
                              <strong>
                                <span
                                  class="my-suggestion-item"
                                  v-text="
                                    `${
                                      suggestion.item.name_transportir || ''
                                    } ( ${
                                      suggestion.item.full_name_transportir ||
                                      'Full Name Transportir'
                                    } )`
                                  "
                                />
                              </strong>
                            </b-card-text>
                            <small class="text-muted">
                              <strong
                                ><span
                                  v-text="
                                    `${dateFormat(suggestion.item.created_at)}`
                                  "
                              /></strong>
                            </small>
                          </div>
                        </div>
                      </template>
                      <template slot="before-suggestions">
                        <div class="ml-1 mt-1">
                          <b-badge
                            href="#"
                            pill
                            :variant="statusSuggestionColor[transportirState]"
                          >
                            <span
                              >
                              {{
                                statusSuggestionChoose[transportirState]
                              }}</span
                            >
                          </b-badge>
                        </div>
                      </template>
                    </vue-autosuggest>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-spinner
                    v-if="transportirStateLoading"
                    class="suggest-loading"
                    small
                    variant="primary"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Full Name Transportir"
                  label-for="vi-transportir-full_name_transportir"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Full Name Transportir"
                    rules="required"
                  >
                    <b-form-input
                      id="vi-transportir-full_name_transportir"
                      v-model="form.transportir.full_name_transportir"
                      v-uppercase
                      placeholder="Full Name Transportir"
                      :readonly="!form.transportir.isNew"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Created BY"
                  label-for="vi-transportir-created_by"
                >
                  <b-form-input
                    id="vi-transportir-created_by"
                    placeholder="Otomatis Terisi"
                    :value="form.transportir.user.full_name"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Phone of Created BY"
                  label-for="vi-agen-phone_of_created_by"
                >
                  <b-form-input
                    id="vi-agen-phone_of_created_by"
                    placeholder="Otomatis Terisi"
                    :value="form.transportir.user.phone"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Created AT"
                  label-for="vi-transportir-created_at"
                >
                  <b-form-input
                    id="vi-transportir-created_at"
                    placeholder="Otomatis Terisi"
                    :value="dateFormat(form.transportir.created_at)"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Land Vehicle -->
        <tab-content
          :title="$t('Land Vehicles')"
          icon="feather icon-truck"
          :lazy="true"
          :before-change="validationFormVehicle"
        >
          <validation-observer ref="vehicleRules" tag="form">
            <b-row v-if="landVehicleOverview">
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  {{ this.$t('Land Vehicles') }}
                  <b-badge
                    href="#"
                    pill
                    :variant="statusSuggestionColor[dm_land_vehicleState]"
                  >
                    <strong
                      ><span v-text="statusSuggestion[dm_land_vehicleState]"
                    /></strong>
                  </b-badge>
                </h5>
                <small class="text-muted">
                  {{ this.$t('Create Your ') }}{{ this.$t('Land Vehicles')
                  }}{{ this.$t(' or Use data on already exist') }}.
                </small>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Plat Nomor Polisi"
                  label-for="vi-dm_land_vehicle-plat_no_pol"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Plat Nomor Polisi"
                    rules="required"
                  >
                    <vue-autosuggest
                      id="vi-dm_land_vehicle-plat_no_pol"
                      v-model="form.dm_land_vehicle.plat_no_pol"
                      v-uppercase
                      :suggestions="[
                        { data: form.dm_land_vehicle.dm_land_vehicles }
                      ]"
                      :get-suggestion-value="getSuggestionValue"
                      :input-props="{
                        id: 'autosuggest__input',
                        class: 'form-control',
                        placeholder: 'KT 1945 DR'
                      }"
                      @focus="
                        getSuggestionValueFocus(
                          $event,
                          'dm_land_vehicle',
                          'plat_no_pol'
                        )
                      "
                      @input="
                        searchForm(
                          $event,
                          'dm_land_vehicle/',
                          'dm_land_vehicle',
                          'plat_no_pol'
                        )
                      "
                    >
                      <template slot-scope="{ suggestion }">
                        <div class="d-flex align-items-center">
                          <div class="detail ml-50">
                            <b-card-text class="mb-0">
                              <strong>
                                <span
                                  class="my-suggestion-item"
                                  v-text="
                                    `${
                                      suggestion.item.plat_no_pol || ''
                                    }, Kapasitas: ${
                                      suggestion.item.capacity || ''
                                    }`
                                  "
                                />
                              </strong>
                            </b-card-text>
                            <small class="text-muted">
                              <strong
                                ><span
                                  v-text="
                                    `${dateFormat(suggestion.item.created_at)}`
                                  "
                              /></strong>
                            </small>
                          </div>
                        </div>
                      </template>
                      <template slot="before-suggestions">
                        <div class="ml-1 mt-1">
                          <b-badge
                            href="#"
                            pill
                            :variant="
                              statusSuggestionColor[dm_land_vehicleState]
                            "
                          >
                            <span
                              >
                              {{
                                statusSuggestionChoose[dm_land_vehicleState]
                              }}</span
                            >
                          </b-badge>
                        </div>
                      </template>
                    </vue-autosuggest>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <b-spinner
                      v-if="dm_land_vehicleStateLoading"
                      class="suggest-loading"
                      small
                      variant="primary"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Kapasitas (Ltr)"
                  label-for="vi-dm_land_vehicle-capacity"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Kapasitas (LTR)"
                    rules="required"
                  >
                    <v-select
                      id="vi-dm_land_vehicle-capacity"
                      v-model="form.dm_land_vehicle.capacity"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="options.capacities"
                      :reduce="(option) => option.value"
                      :disabled="!form.dm_land_vehicle.isNew"
                      :selectable="(option) => !option.value.includes('null')"
                      label="text"
                      item-text="text"
                      item-value="value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Jumlah Muatan (Ltr)"
                  label-for="vi-dm_land_vehicle-jml_muatan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Jumlah Muatan (LTR)"
                    :rules="{
                      required: true,
                      max_value: form.dm_land_vehicle.capacity
                    }"
                  >
                    <b-input-group append="Ltr">
                      <cleave
                        id="vi-dm_land_vehicle-jml_muatan"
                        v-model="form.schedule.jml_muatan"
                        class="form-control"
                        :raw="true"
                        :options="options.number"
                        placeholder="Contoh 10.000 (dot automaticaly )"
                      />
                    </b-input-group>
                    <small class="text-danger"
                      >{{ errors[0] }}
                      {{
                        Array.from(errors).toString().includes('lebih dari')
                          ? ' ( Kapasitas )'
                          : ''
                      }}</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Created BY"
                  label-for="vi-dm_land_vehicle-created_by"
                >
                  <b-form-input
                    id="vi-dm_land_vehicle-created_by"
                    placeholder="Otomatis Terisi"
                    :value="form.dm_land_vehicle.user.full_name"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Phone of Created BY"
                  label-for="vi-dm_land_vehicle-phone_of_created_by"
                >
                  <b-form-input
                    id="vi-dm_land_vehicle-phone_of_created_by"
                    placeholder="Otomatis Terisi"
                    :value="form.dm_land_vehicle.user.phone"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Created AT"
                  label-for="vi-dm_land_vehicle-created_at"
                >
                  <b-form-input
                    id="vi-dm_land_vehicle-created_at"
                    placeholder="Otomatis Terisi"
                    :value="dateFormat(form.dm_land_vehicle.created_at)"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Driver -->
        <tab-content
          :title="$t('Person V1')"
          icon="feather icon-user"
          :lazy="true"
          :before-change="validationFormDriver"
        >
          <validation-observer ref="driverRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  Drivers
                  <b-badge
                    href="#"
                    pill
                    :variant="statusSuggestionColor[dm_personalState]"
                  >
                    <strong
                      ><span v-text="statusSuggestion[dm_personalState]"
                    /></strong>
                  </b-badge>
                </h5>

                <small class="text-muted">
                  {{ this.$t('Create Your ') }}Drivers
                  {{ this.$t(' or Use data on already exist') }}. ( Bisa dicari
                  dengan
                  <code>
                    <strong>Nama Lengkap</strong>
                  </code>
                  ataupun dengan
                  <code>
                    <strong>Nomor KTP</strong>
                  </code>
                  )
                </small>
              </b-col>
            </b-row>
            <div>
              <div>
                <b-form
                  ref="form"
                  :style="{ height: trHeight }"
                  class="repeater-form"
                  @submit.prevent="repeateAgain"
                >
                  <!-- Row Loop -->
                  <b-row
                    v-for="(item, index) in items"
                    :id="item.id"
                    :key="index"
                    ref="row"
                  >
                    <!-- Item Name -->
                    <b-col md="3">
                      <b-form-group
                        label="Driver Name"
                        label-for="vi-dm_personal-full_name"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Driver Name"
                          rules="required"
                        >
                          <vue-autosuggest
                            id="vi-dm_personal-full_name"
                            v-model="form.dm_personal.full_name"
                            v-uppercase
                            :suggestions="[
                              { data: form.dm_personal.dm_personals }
                            ]"
                            :get-suggestion-value="getSuggestionValue"
                            :input-props="{
                              id: 'autosuggest__input',
                              class: 'form-control',
                              placeholder: 'Full Name'
                            }"
                            @focus="
                              getSuggestionValueFocus(
                                $event,
                                'dm_personal',
                                'full_name'
                              )
                            "
                            @input="
                              searchForm(
                                $event,
                                'dm_personal/',
                                'dm_personal',
                                'full_name'
                              )
                            "
                          >
                            <template slot-scope="{ suggestion }">
                              <div class="d-flex align-items-center">
                                <div class="detail ml-50">
                                  <b-card-text class="mb-0">
                                    <strong>
                                      <span
                                        class="my-suggestion-item"
                                        v-text="
                                          `${suggestion.item.full_name}, KTP: ${
                                            suggestion.item.no_ktp || ''
                                          }, HP: ${
                                            suggestion.item.phone || ''
                                          } `
                                        "
                                      />
                                    </strong>
                                  </b-card-text>
                                  <small class="text-muted">
                                    <strong
                                      ><span
                                        v-text="
                                          `${dateFormat(
                                            suggestion.item.created_at
                                          )}`
                                        "
                                    /></strong>
                                  </small>
                                </div>
                              </div>
                            </template>
                            <template slot="before-suggestions">
                              <div class="ml-1 mt-1">
                                <b-badge
                                  href="#"
                                  pill
                                  :variant="
                                    statusSuggestionColor[dm_personalState]
                                  "
                                >
                                  <span
                                    >
                                    {{
                                      statusSuggestionChoose[dm_personalState]
                                    }}</span
                                  >
                                </b-badge>
                              </div>
                            </template>
                          </vue-autosuggest>
                          <small class="text-danger">{{ errors[0] }}</small>
                          <b-spinner
                            v-if="dm_personalStateLoading"
                            class="suggest-loading"
                            small
                            variant="primary"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Cost -->
                    <b-col md="3">
                      <b-form-group label="No KTP" label-for="cost">
                        <validation-provider
                          #default="{ errors }"
                          name="No KTP"
                          :rules="{
                            required: true,
                            min: 16
                          }"
                        >
                          <!-- <b-form-input
                            id="cost"
                            v-model="form.dm_personal.no_ktp"
                            type="number"
                            placeholder="otomatis terisi"
                            :readonly="!form.dm_personal.isNew"
                          /> -->
                          <cleave
                            id="cost"
                            v-model="form.dm_personal.no_ktp"
                            class="form-control"
                            :raw="true"
                            :options="options.noKTP"
                            :readonly="!form.dm_personal.isNew"
                            placeholder="otomatis terisi"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="3">
                      <b-form-group label="No HP" label-for="quantity">
                        <validation-provider
                          #default="{ errors }"
                          name="No HP"
                          :rules="{
                            required: true,
                            min: 10,
                            max: 13
                          }"
                        >
                          <!-- <cleave
                            id="quantity"
                            v-model="form.dm_personal.phone"
                            class="form-control"
                            :raw="true"
                            :options="options.prefix"
                            :readonly="!form.dm_personal.isNew"
                            placeholder="otomatis terisi"
                          /> -->
                          <b-form-input
                            id="quantity"
                            v-model="form.dm_personal.phone"
                            type="number"
                            placeholder="otomatis terisi"
                            :readonly="!form.dm_personal.isNew"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Quantity -->

                    <b-col md="3">
                      <b-form-group
                        label="Phone of Created BY"
                        label-for="vi-dm_personal-phone_of_created_by"
                      >
                        <b-form-input
                          id="vi-dm_personal-phone_of_created_by"
                          placeholder="Otomatis Terisi"
                          :value="form.dm_personal.user.phone"
                          readonly
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Created BY" label-for="created_by">
                        <b-form-input
                          id="created_by"
                          type="text"
                          readonly
                          :value="form.dm_personal.user.full_name"
                          placeholder="otomatis terisi"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Created AT" label-for="created_at">
                        <b-form-input
                          id="created_at"
                          type="text"
                          :value="dateFormat(form.dm_personal.created_at)"
                          readonly
                          placeholder="otomatis terisi"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Updated AT" label-for="updated_at">
                        <b-form-input
                          id="updated_at"
                          type="text"
                          readonly
                          :value="dateFormat(form.dm_personal.updated_at)"
                          placeholder="otomatis terisi"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Remove Button -->
                    <!-- <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItem(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>Delete</span>
                    </b-button>
                  </b-col> -->
                    <b-col cols="12">
                      <hr />
                    </b-col>
                  </b-row>
                </b-form>
              </div>
              <!-- <b-button
              variant="primary"
              @click="repeateAgain"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add New</span>
            </b-button> -->
            </div>
          </validation-observer>
        </tab-content>

        <!-- Schedule -->
        <tab-content
          :title="$t('Schedules_LH')"
          icon="feather icon-calendar"
          :lazy="true"
          :before-change="validationFormSchedule"
        >
          <validation-observer ref="scheduleRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Schedules</h5>
                <small class="text-muted"
                  >{{ this.$t('Create Your ') }}Schedules</small
                >
              </b-col>
              <b-col md="6">
                <b-form-group label="Schedule IN" label-for="vi-schedule_in">
                  <validation-provider
                    #default="{ errors }"
                    name="Schedule IN"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="form.schedule.schedule_in"
                      class="form-control"
                      static="true"
                      :state="errors.length > 0 ? false : null"
                      :config="
                        $router.currentRoute.params.id !== undefined
                          ? dpconfigRevisi
                          : dpconfig
                      "
                      @on-change="onStartChange"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Schedule OUT" label-for="vi-schedule_out">
                  <validation-provider
                    #default="{ errors }"
                    name="Schedule OUT"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="form.schedule.schedule_out"
                      class="form-control"
                      static="true"
                      :state="errors.length > 0 ? false : null"
                      :config="configs.end"
                      @on-change="onEndChange"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </overlay-loading>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BRow, BCol, BFormGroup, BFormInput } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import OverlayLoading from '@views/components/OverlayLoading.vue'
import { VueAutosuggest } from 'vue-autosuggest'
import { heightTransition } from '@core/mixins/ui/transition'
import VSelect from 'vue-select'
import moment from 'moment'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import '@core/scss/vue/libs/vue-autosuggest.scss'
import { v4 as uuidv4 } from 'uuid'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    VSelect,
    ValidationProvider,
    ValidationObserver,
    Indonesian,
    flatPickr,
    VueAutosuggest,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    OverlayLoading,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Cleave
  },
  mixins: [heightTransition],
  data() {
    return {
      domain: process.env.VUE_APP_URL,
      userData: JSON.parse(localStorage.getItem('userData')),
      groupName: JSON.parse(localStorage.getItem('userData')).group?.name ?? '',
      formTitle: '',
      dpconfig: {
        // default
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: false,
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: new Date(),
        maxDate: null
      },
      dpconfigRevisi: {
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: false,
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null
      },
      configs: {
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: false,
          dateFormat: 'Y-m-d H:i',
          locale: Indonesian,
          minDate: null
        }
      },
      options: {
        capacities: [
          { text: '5.000 Ltr', value: '5000' },
          { text: '10.000 Ltr', value: '10000' },
          { text: '16.000 Ltr', value: '16000' },
          { text: '20.000 Ltr', value: '20000' }
        ],
        creditCard: {
          creditCard: true
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd']
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US'
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's']
        },
        number: {
          // numeral: true,
          // numeralThousandsGroupStyle: 'thousand',
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
          // suffix: 'LTR',
        },
        no_pol: {
          numeral: true,
          blocks: [4, 3, 3],
          uppercase: true
        },
        noKTP: {
          delimiters: [' ', ' ', ' '],
          blocks: [4, 4, 4, 4],
          uppercase: true
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true
        },
        prefix: {
          phone: true,
          phoneRegionCode: 'ID'
        }
      },
      payload: [],
      form: {
        currentModel: '',
        currentFieldSuggest: '',
        currentFieldSuggestChild: null,

        isAgenAndTrans: false,
        showIsAgenAndTrans: false,
        temp_transportir_id: '',

        transportir: {
          updateID: null,
          isEverRun: false,
          uri: 'transportir/',
          isNew: true,
          name_transportir: '',
          full_name_transportir: '',
          dm_personal: {
            uri: 'dm_personal/',
            isNew: true,
            id: 0,
            full_name: '',
            dm_personals: []
          },
          user: {
            full_name: '',
            phone: ''
          },
          created_at: moment(),
          transportirs: []
        },
        dm_land_vehicle: {
          updateID: null,
          isEverRun: false,
          uri: 'dm_land_vehicle/',
          isNew: true,
          plat_no_pol: '',
          capacity: null,
          user: {
            full_name: '',
            phone: ''
          },
          created_at: moment(),
          updated_at: moment(),
          dm_land_vehicles: []
        },
        temp: {
          dm_personal: {
            full_name: ''
          }
        },
        dm_personal: {
          updateID: null,
          isEverRun: false,
          uri: 'dm_personal/',
          isNew: true,
          full_name: '',
          no_ktp: '',
          phone: '',
          user: {
            full_name: '',
            phone: ''
          },
          created_at: moment(),
          updated_at: moment(),
          dm_personals: []
        },
        schedule: {
          uri: 'form2_land_vehicle/',
          no_document: null,
          schedule_in: null,
          schedule_out: null,
          transportir_id: null,
          dm_land_vehicle_id: null,
          dm_personal_id: null,
          full_name: null,
          jml_muatan: null,
          uuid: uuidv4()
        }
      },
      dataOverview: [],
      transportirOverview: [],
      landVehicleOverview: [],
      fetchLoading: false,
      selectedContry: 'select_value',
      selectedLanguage: 'nothing_selected',
      items: [
        {
          id: 1,
          selected: 'male',
          selected1: 'designer',
          prevHeight: 0
        }
      ],
      nextTodoId: 2,
      payloadNotification: {},

      transportirState: 0,
      dm_land_vehicleState: 0,
      dm_personalState: 0,
      statusSuggestion: {
        0: 'Membuat Bank Data Baru',
        1: 'Menggunakan Bank Data',
        2: 'Menggunakan Bank Data & Membuat Bank Data Baru'
      },
      statusSuggestionColor: {
        0: 'primary',
        1: 'warning',
        2: 'success'
      },
      statusSuggestionChoose: {
        0: 'Pilih Data yang pernah dibuat [ reuse-data ]',
        1: 'cancel [ reuse-data ] by click "space" then "backspace"',
        2: 'Pilih Data yang pernah dibuat [ reuse-data ]'
      },

      transportirStateLoading: false,
      dm_land_vehicleStateLoading: false,
      dm_personalStateLoading: false
    }
  },
  mounted() {
    this.initTrHeight()
    this.formTitle = `📘 FORM TRUK MASUK KE ${
      this.userData.group?.name ?? '-'
    } (${this.userData.group?.description ?? ' -'} )`
    const dataID = router.currentRoute.params.id
    if (dataID) {
      const { tabs } = this.$refs.wizard
      tabs.forEach((tab) => (tab.checked = true))
      this.$refs.wizard.maxStep = tabs.length
    }
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    if (
      this.$store.state.app.tokenNotification === '' ||
      this.$store.state.app.tokenNotification === null
    ) {
      this.$swal({
        title: 'Info, Notifikasi Bermasalah!',
        text: ' Kami telah secara otomatis mendeteksi pemberitahuan token sudah kedaluwarsa atau belum mengaktifkan izin notifikasi browser, Fix Notifikasi untuk memperbarui token dan coba kembali ke halaman ini.\njika Anda berada di browser Safari, coba lagi dengan browser Chrome/Firefox dengan perangkat Android atau Laptop/Komputer (OS windows/macOS)\n\n',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Fix (kedaluwarsa)',
        cancelButtonText: 'Abaikan Fitur Ini (iOS)',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          this.$forceUpdate()
          if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
          this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
        } else {
          console.log('mengabaikan')
        }
      })
    }
    this.fetchData()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    moment,
    onTabChange(prevTab, currentTab) {
      if (currentTab === this.$refs.wizard.maxStep - 1) {
        console.log('houya')
        this.dpconfigRevisi.minDate = this.form.schedule_in
        this.dpconfigRevisi.maxDate = this.form.schedule_out
      }
    },
    onStartChange(selectedDates, dateStr, instance) {
      this.configs.end.minDate = dateStr
    },
    onEndChange(selectedDates, dateStr, instance) {
      this.dpconfig.maxDate = dateStr
    },
    async sentNotification(isRevisiMsg) {
      await this.setPayloadNotification(isRevisiMsg)
      useJwt.http
        .post('notification', this.payloadNotification)
        .then(() => {
          // console.log('data: ', data)
        })
        .catch((error) => {
          // console.log('data err: ', err)
          this.$swal({
            title: 'Error!',
            text: ` Form Masuk dan Keluar Berhasil dibuat tetapi ada kesalahan sistem untuk notification! ${error}. Ini tidak berdampak pada kerja sistem utama.`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          }).then(async () => {
            if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
          })
        })
    },
    async setPayloadNotification(isRevisiMsg) {
      const path = '/#/monitoring/jalur-darat/form/type-2'
      const route = this.domain + path

      // TODO: set from backend
      const group = this.userData.group?.name ?? null

      const mapType = {
        TEM: 'light-success',
        PATRA: 'light-warning',
        'PT PIL': 'light-info',
        SLB: 'light-danger',
        null: 'light-danger',
        undefined: 'light-danger'
      }
      const mapAction = {
        TEM: ` 📗 - ${isRevisiMsg}`,
        PATRA: ` 📔 - ${isRevisiMsg}`,
        'PT PIL': ` 📘 - ${isRevisiMsg}`,
        SLB: ` 📕 - ${isRevisiMsg}`,
        null: ` 📕 - ${isRevisiMsg}`
      }

      let type = ''
      let action = ''
      try {
        type = mapType[group]
        if (type === undefined) {
          type = 'light-danger'
        }
        action = mapAction[group]
        if (action === undefined) {
          action = ` 📕 - ${isRevisiMsg}`
        }
      } catch (err) {
        type = 'light-primary'
        action = ` 📗 - ${isRevisiMsg}`
      }
      const title = this.userData.full_name

      this.payloadNotification = {
        form_id: '',
        registrationToken: this.$store.state.app.tokenNotification,
        title,
        url: route,
        form_type: 'T2',
        type, // light-success
        action, //  📗 - Published || Revisied
        /* data kendaraan */
        plat_no_pol: this.form.dm_land_vehicle.plat_no_pol,
        driver_name: this.form.dm_personal.full_name,
        // group, // req.group_id
        is_read: false,
        tag: 'land_route'
      }
      console.log('payload(1)', this.payloadNotification)
    },
    dateFormat(data) {
      return moment(data).format('D MMMM YYYY - hh:mm A')
    },
    getSuggestionValueFocus(
      event,
      model,
      currentSuggest,
      childSuggesst = null
    ) {
      console.log(model)
      this.form.currentModel = model
      this.form.currentFieldSuggest = currentSuggest
      this.form.currentFieldSuggestChild = childSuggesst
    },
    getSuggestionValue(suggestion) {
      const model = this.form.currentModel
      // loading|state
      this[`${model}State`] = 1
      const field = this.form.currentFieldSuggest
      const fieldChild = this.form.currentFieldSuggestChild

      let result = ''
      if (fieldChild === '' || fieldChild === null || fieldChild === 'null') {
        const currentLoadData = this.form[`${model}`][`${model}s`]

        this.form[`${model}`] = {
          isEverRun: false, // default
          isNew: false,
          uri: this.form[`${model}`].uri,
          [`${model}s`]: currentLoadData,
          ...suggestion.item
        }

        // fix null penanggung jawab
        if ('dm_personal' in suggestion.item) {
          if (suggestion.item.dm_personal === null) {
            const reDefine = {
              ...suggestion.item,
              dm_personal: {
                id: 0,
                full_name: 'HARAP DIISI',
                dm_personals: [],
                isEverRun: false, // default
                isNew: false,
                uri: this.form[`${model}`].dm_personal.uri
              }
            }
            console.log(reDefine)
            this.form[`${model}`] = {
              isEverRun: false, // default
              isNew: false,
              uri: this.form[`${model}`].uri,
              [`${model}s`]: currentLoadData,
              ...reDefine
            }
          } else {
            console.log(
              'aws dm_personal is not null re define',
              field,
              fieldChild
            )
            const reDefine = {
              ...suggestion.item,
              dm_personal: {
                ...suggestion.item.dm_personal,
                isEverRun: false, // default
                isNew: false,
                uri: this.form[`${model}`].dm_personal.uri
              }
            }
            this.form[`${model}`] = {
              isEverRun: false, // default
              isNew: false,
              uri: this.form[`${model}`].uri,
              [`${model}s`]: currentLoadData,
              ...reDefine
            }
          }
        }

        result = suggestion.item[`${field}`]
      } else {
        const split = fieldChild.split('.')
        const currentLoadData =
          this.form[`${model}`][`${split[0]}`][`${split[0]}s`]
        this.form[`${model}`][`${split[0]}`] = {
          isEverRun: false, // default
          isNew: false,
          uri: this.form[`${model}`][`${split[0]}`].uri,
          [`${split[0]}s`]: currentLoadData,
          ...suggestion.item
        }
        this.form[`${model}`] = {
          ...this.form[`${model}`],
          [`${split[0]}_id`]: suggestion.item.id
        }
        result = suggestion.item[`${split[1]}`]
      }
      return result
    },
    searchForm(keyword, uri, model, currentSuggest, childSuggesst = null) {
      // loading|state
      if (this[`${model}State`] === 1) {
        this[`${model}State`] = 2
      }
      if (keyword) {
        clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => {
          // loading|state
          if (childSuggesst === null) {
            this[`${model}StateLoading`] = true
          } else {
            this.dm_personalStateLoading = true
          }
          useJwt.http
            .get(`${uri}`, { params: { keyword, single_search: true } })
            .then((response) => {
              if (response.data.total_items === 0) {
                if (childSuggesst === null) {
                  // no data
                  // this.form.isAgenAndTrans = false
                  this.form[`${model}`].isNew = true
                  this.form[`${model}`][`${model}s`] = []

                  // auto filled
                  this.form[`${model}`].user.full_name = null
                  this.form[`${model}`].created_at = moment()
                  this.form[`${model}`].user.phone = null
                } else {
                  // this.form.isAgenAndTrans = false
                  const split = childSuggesst.split('.')
                  this.form[`${model}`][`${split[0]}`].isNew = true
                  this.form[`${model}`][`${split[0]}`][`${split[0]}s`] = ''
                }
              } else if (childSuggesst === null) {
                this.form[`${model}`].isNew = true
                // this.form.isAgenAndTrans = false
                this.form[`${model}`][`${model}s`] = response.data[`${model}s`]
              } else {
                // this.form.isAgenAndTrans = false
                const split = childSuggesst.split('.')
                this.form[`${model}`][`${split[0]}`].isNew = true
                this.form[`${model}`][`${split[0]}`][`${split[0]}s`] =
                  response.data[`${split[0]}s`]
                // loading|state
                this.dm_personalStateLoading = false
              }
              // loading|state
              this[`${model}StateLoading`] = false
              this.dm_personalStateLoading = false
            })
            .catch((e) => {
              // this.form.isAgenAndTrans = false
              this.form[`${model}`].isNew = true
              this.form[`${model}`].user.full_name = null
              this.form[`${model}`][`${model}s`] = []
              // loading|state
              this[`${model}StateLoading`] = false
              this.dm_personalStateLoading = false
            })
        }, 300)
      }
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId)
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    // end
    async fetchData() {
      const uriAPI = 'form2_land_vehicle'
      const dataID = router.currentRoute.params.id

      if (dataID !== undefined) {
        this.fetchLoading = true

        await useJwt.http
          .get(`${uriAPI}/${dataID}`)
          .then((response) => {
            this.fetchLoading = false
            console.log('fetchData:', response.data.form2_land_vehicles)

            const dataOverview = response.data.form2_land_vehicles
            const reDefineTrans = {
              ...dataOverview.transportir,
              dm_personal: {
                ...dataOverview.transportir.dm_personal,
                updateID: dataOverview.transportir.dm_personal.id,
                isEverRun: false, // default
                isNew: false,
                uri: 'dm_personal/'
              }
            }
            this.form.transportir = {
              ...reDefineTrans,
              updateID: dataOverview.transportir.id,
              isEverRun: false,
              uri: 'transportir/',
              isNew: false
            }

            this.form.transportir = {
              ...dataOverview.transportir,
              updateID: dataOverview.transportir.id,
              isEverRun: false,
              uri: 'transportir/',
              isNew: false
            }
            this.form.dm_land_vehicle = {
              ...dataOverview.dm_land_vehicle,
              updateID: dataOverview.dm_land_vehicle.id,
              isEverRun: false,
              uri: 'dm_land_vehicle/',
              isNew: false
            }
            this.form.dm_personal = {
              ...dataOverview.dm_personal,
              updateID: dataOverview.dm_personal.id,
              isEverRun: false,
              uri: 'dm_personal/',
              isNew: false
            }

            // payload
            this.form.schedule.schedule_in = dataOverview.schedule_in
            this.form.schedule.schedule_out = dataOverview.schedule_out
            this.form.schedule.jml_muatan = dataOverview.jml_muatan
            this.form.schedule.no_document = dataOverview.no_document

            this.form.schedule.transportir_id = dataOverview.transportir.id
            this.form.schedule.dm_land_vehicle_id =
              dataOverview.dm_land_vehicle.id
            this.form.schedule.dm_personal_id = dataOverview.dm_personal.id
            this.form.schedule.full_name = dataOverview.dm_personal.full_name
            console.log('init payload:', this.form.schedule)
          })
          .catch((err) => {
            this.fetchLoading = false
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error load data ${err}`,
                icon: 'XIcon',
                variant: 'danger'
              }
            })
          })
          .finally(() => {
            this.fetchLoading = false
          })
      }
    },
    async getIDCreated(jwt, model) {
      let resultID
      await Promise.resolve(jwt).then(async (response) => {
        resultID = response.data[`${model}`].id
      })
      return resultID
    },
    async handlingDistributionData(model, strModel, updateID) {
      console.log(
        'action for ',
        model,
        ' and -> ',
        strModel,
        'updateID: ',
        updateID
      )
      if (updateID && model.isNew === false) {
        console.log('doing update:', updateID)
        const jwt = await useJwt.http.put(`${model.uri}${updateID}`, model)
        console.log('is new = false: ', strModel)
        this.form.schedule[`${strModel}_id`] = model.id
      } else if (model.isNew === true) {
        console.log('doing create')
        const jwt = await useJwt.http.post(model.uri, model)
        if (jwt) {
          if (!strModel.includes('penanggung')) {
            const id = await this.getIDCreated(jwt, strModel)
            console.log(`if getIDCreated, for ${model} ${strModel}_id = ${id}`)
            this.form.schedule[`${strModel}_id`] = id
          } else {
            const id = await this.getIDCreated(jwt, 'dm_personal')
            console.log(
              `else getIDCreated for ${model}, ${strModel}_id = ${id}`
            )
            console.log(model)
            this.form[`${model.root_model}`].dm_personal_id = id
          }
        }
      } else {
        console.log(`doing use on exist data: ${strModel}_id`, model.id)
        this.form.schedule[`${strModel}_id`] = model.id
      }
    },
    validationFormVehicle() {
      return new Promise((resolve, reject) => {
        this.$refs.vehicleRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormTransportir() {
      return new Promise((resolve, reject) => {
        this.$refs.transportirRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormDriver() {
      return new Promise((resolve, reject) => {
        this.$refs.driverRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSchedule() {
      return new Promise((resolve, reject) => {
        this.$refs.scheduleRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    async formSubmitted() {
      this.$refs.wizard.hideButtons = true
      const dataID = router.currentRoute.params.id
      const {
        transportir,
        dm_land_vehicle,
        dm_personal,
        schedule,
        isAgenAndTrans
      } = this.form

      let { dm_personal: transportirPenanggungJawab } = transportir
      transportirPenanggungJawab = {
        ...transportirPenanggungJawab,
        root_model: 'transportir',
        no_ktp: `${uuidv4()}2`,
        uri: 'dm_personal/'
      }

      let breakSwalError = false

      if (transportirPenanggungJawab.isEverRun === false) {
        await this.handlingDistributionData(
          transportirPenanggungJawab,
          'transportir_penanggung_jawab',
          transportirPenanggungJawab.updateID
        )
          .then(() => {
            transportirPenanggungJawab.isEverRun = true
          })
          .catch((error) => {
            console.log('an error :', error)
            this.$refs.wizard.hideButtons = false
            this.fetchLoading = false
            if (breakSwalError === false) {
              this.$swal({
                title: 'Error!',
                text: `${
                  error.response.data.message ??
                  'Data transportirPenanggungJawab Error, Silahkan cek data kembali.'
                }`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              }).then(async () => {
                transportirPenanggungJawab.isEverRun = false
              })
              breakSwalError = true
            }
          })
      }

      if (isAgenAndTrans === false && transportir.isEverRun === false) {
        await this.handlingDistributionData(
          transportir,
          'transportir',
          transportir.updateID
        )
          .then(() => {
            transportir.isEverRun = true
          })
          .catch((error) => {
            console.log('an error :', error.response.data)
            this.$refs.wizard.hideButtons = false
            this.fetchLoading = false
            breakSwalError = true
            this.$swal({
              title: 'Error!',
              text: `${
                error.response.data.message ??
                'Data Transportir Error, Silahkan cek data kembali.'
              }`,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            }).then(async () => {
              transportir.isEverRun = false
            })
          })
      }
      if (dm_land_vehicle.isEverRun === false) {
        await this.handlingDistributionData(
          dm_land_vehicle,
          'dm_land_vehicle',
          dm_land_vehicle.updateID
        )
          .then(() => {
            dm_land_vehicle.isEverRun = true
          })
          .catch((error) => {
            console.log('an error :', error.response.data)
            this.$refs.wizard.hideButtons = false
            this.fetchLoading = false
            if (breakSwalError === false) {
              this.$swal({
                title: 'Error!',
                text: `${
                  error.response.data.message ??
                  'Data Kendaraan Darat Error, Silahkan cek data kembali.'
                }`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              }).then(async () => {
                dm_land_vehicle.isEverRun = false
              })
              breakSwalError = true
            }
          })
      }
      if (dm_personal.isEverRun === false) {
        await this.handlingDistributionData(
          dm_personal,
          'dm_personal',
          dm_personal.updateID
        )
          .then(() => {
            dm_personal.isEverRun = true
          })
          .catch((error) => {
            console.log('an error :', error.response.data)
            this.$refs.wizard.hideButtons = false
            this.fetchLoading = false
            if (breakSwalError === false) {
              this.$swal({
                title: 'Error!',
                text: `${
                  error.response.data.message ??
                  'Data Orang/Sopir Error, Silahkan cek data kembali.'
                }`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              }).then(async () => {
                dm_personal.isEverRun = false
              })
              breakSwalError = true
            }
          })
      }
      schedule.full_name = dm_personal.full_name
      schedule.plat_no_pol = dm_land_vehicle.plat_no_pol
      if (dataID) {
        console.log('payload update:', schedule)
        await useJwt.http
          .put(`${schedule.uri}${dataID}`, schedule)
          .then(async () => {
            this.fetchLoading = false
            this.$swal({
              title: 'Good job!',
              text: ' Berhasil merivisi Form Masuk & Keluar!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            }).then(async () => {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            })
            await this.sentNotification('Revisi Data')
          })
          .catch((e) => {
            this.$refs.wizard.hideButtons = false
            if (breakSwalError === false) {
              this.fetchLoading = false
              this.$swal({
                title: 'Error!',
                text: ` Mohon maaf proses update error : ${e}`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            }
          })
      } else {
        await useJwt.http
          .post(schedule.uri, schedule)
          .then(async () => {
            this.$swal({
              title: 'Good job!',
              text: ' Berhasil membuat Form Masuk & Keluar!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            }).then(async () => {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            })
            await this.sentNotification('Published')
            this.$refs.wizard.hideButtons = false
          })
          .catch((e) => {
            this.$refs.wizard.hideButtons = false
            if (breakSwalError === false) {
              this.$swal({
                title: 'Error!',
                text: ` Mohon maaf proses submit error : ${e}`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            }
          })
      }
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
#app {
  .vertical-layout {
    .app-content {
      .content-wrapper {
        .content-body {
          .vue-form-wizard {
            .wizard-card-footer {
              margin-left: 19.7em !important;
              @media (min-width: 400px) and (max-width: 900px) {
                margin-left: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" scoped>
#app {
  .app-content {
    .content-wrapper {
      .content-body {
        #form_masuk {
          .b-overlay-wrap {
            .vue-form-wizard {
              .wizard-navigation {
                .wizard-tab-content {
                  .row {
                    .suggest-loading {
                      // all of suggestion value
                      z-index: 99;
                      right: 2em;
                      top: 2.5em;
                      position: absolute; // inside form-input
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
